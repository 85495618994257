import { ScriptHTMLAttributes, FC } from 'react'
import { markup } from '@lifedot/utils'

const jsonLd = JSON.stringify({
  '@context': 'http://schema.org',
  '@type': 'Organization',
  url: 'https://life-design.a-tm.co.jp',
  name: '株式会社エイチームライフデザイン',
  foundingDate: '2013年8月22日',
  parentOrganization: '株式会社エイチームホールディングス',
  sameAs: 'https://life-design.a-tm.co.jp/corporate/',
  logo: {
    '@type': 'ImageObject',
    url: 'https://life-design.a-tm.co.jp/images/common/AteamLogo.png',
    height: '193',
    width: '671'
  },
  address: {
    '@type': 'PostalAddress',
    streetAddress: '名駅三丁目28-12大名古屋ビルヂング32階',
    addressLocality: '名古屋市中村区',
    addressRegion: '愛知県',
    postalCode: '450-6432',
    addressCountry: 'JP'
  }
})

export const OrganizationJsonLd: FC<ScriptHTMLAttributes<HTMLScriptElement>> = (
  props
) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={markup(jsonLd)}
      {...props}
    />
  )
}
